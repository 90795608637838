import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDnFp6ZhIWw5C2kk-fowneYkvFFlCBFvGc",
  authDomain: "allstar-painting.firebaseapp.com",
  projectId: "allstar-painting",
  storageBucket: "allstar-painting.appspot.com",
  messagingSenderId: "195776933403",
  appId: "1:195776933403:web:0412b889fae769a07612be",
  measurementId: "G-7QVFY7EB6Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { app, db };