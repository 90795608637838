import { images } from "../images/content";

function NewService() {
  return (
    <div>
      <div className="services-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].fence}
                  className="card-img-top"
                  alt="Fence Painting"
                />
                <div className="card-body">
                  <h5 className="card-title">Fence Painting</h5>
                  <p className="card-text">
                    High-quality fence painting services to enhance your outdoor space.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].staircase1}
                  className="card-img-top"
                  alt="Staircase Painting"
                />
                <div className="card-body">
                  <h5 className="card-title">Beautiful Staircase Painting</h5>
                  <p className="card-text">
                    Elegant and durable painting solutions for wooden staircases.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].kitchen1}
                  className="card-img-top"
                  alt="Kitchen Painting"
                />
                <div className="card-body">
                  <h5 className="card-title">Kitchen Painting</h5>
                  <p className="card-text">
                    Transform your kitchen with our expert painting services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* New services */}
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].plasteringwork1}
                  className="card-img-top"
                  alt="Plastering Work"
                />
                <div className="card-body">
                  <h5 className="card-title">Plastering Work</h5>
                  <p className="card-text">
                    Professional plastering services for a flawless finish.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].concrete}
                  className="card-img-top"
                  alt="Concrete Painting"
                />
                <div className="card-body">
                  <h5 className="card-title">Concrete Painting</h5>
                  <p className="card-text">
                    Durable and aesthetic painting solutions for concrete surfaces.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].berwickschool1}
                  className="card-img-top"
                  alt="Big Project"
                />
                <div className="card-body">
                  <h5 className="card-title">Big Project</h5>
                  <p className="card-text">
                    Comprehensive painting services for large-scale projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewService;