import React from 'react';
import '../App.css'; // Ensure you import the CSS file

function Contact() {
  return (
    <div className="contact-section py-5">
      <div className="container">
        <h2 className="text-center mb-4">Contact Us</h2>
        <div className="row">
          <div className="col-md-6 mb-3">
            <h5>Address</h5>
            <p>Melbourne<br />Victoria</p>
          </div>
          <div className="col-md-6 mb-3">
            <h5>Phone</h5>
            <p>📞 0450854559</p>
          </div>
          <div className="col-md-6 mb-3">
            <h5>Email</h5>
            <p>📧 contact@allstarpainting.com</p>
          </div>
          <div className="col-md-6 mb-3">
            <h5>Follow Us</h5>
            <p>
              <a href="https://www.instagram.com/all_stars_painting?igsh=ZDQ2M2kyZHZveWR6&utm_source=qr" target="_blank" rel="noopener noreferrer">Instagram</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;