export const images = [
    {
        staircase1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/311103573_407195541424422_9611736083752707_n.jpg',
        hallway3: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455713493_2245925719103601_1511375914324667979_n.jpg',
        
        berwickschool: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/442284873_1736410243434631_5538670490064823381_n.jpg',
        berwickschool1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/442345459_959588795629765_4479659230575254560_n.jpg',
        berwickschool2: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/442427843_444319964814074_4255643320791461449_n.jpg',
        berwickschool3: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/442438748_1110057816941008_5907879303980241187_n.jpg',
       
       
        berwickschool4: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/442442142_398568576493998_5909598763468208680_n.jpg',
        livingroom1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455139755_518276144204492_8510250892676075030_n.jpg',
        behindtv: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455358243_1638956243564621_6253542691666575959_n.jpg',
        
        bedroom5: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455364994_1040814710908949_5720086518704450693_n.jpg',
        livingroom: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455358243_1040804647710880_9029157963700971388_n.jpg',
        kitchen2: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455600464_1224410278484178_7200337163573131250_n.jpg',
        insidehouse: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/302228908_106913562068784_6534528092687637554_n.jpg',
        hallway: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/454403387_1550401652212541_2386751405078875080_n.jpg',
        
        
        hallway2: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/352215910_587771990167051_4258420042530160351_n.jpg',
        upstairs: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/457020964_8102921983136138_4387257909898239117_n.jpg',
        upstairs2: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/456856548_1023520598974261_8941450626750643553_n.jpg',
        hallway4: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455713493_2245925719103601_1511375914324667979_n.jpg',
        bedroom5: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455364994_1040814710908949_5720086518704450693_n.jpg',
        kitchen: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455247185_1217864185873144_4869954590717547904_n.jpg',
        kitchen1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455326908_1292277518851547_6621151250702803966_n.jpg',
        converpage: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/pexels-polina-tankilevitch-5583116.jpg',
        test: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/456856548_1023520598974261_8941450626750643553_n.jpg',
        fronthouse1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/369227882_1122128735415181_4172596964244359375_n.jpg', 
        hallway1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/457020964_8102921983136138_4387257909898239117_n.jpg',
        concrete: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/301607801_614234683440270_270503159458067699_n.jpg',
        fence1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/301659134_632353244877355_6738065964194905488_n.jpg',
        working: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/309027030_757620692007019_4104218182711136105_n.jpg',
        school: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/309884587_661349098892633_5986407035950479292_n.jpg',
        wallpaper: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/311214569_846882832995989_2862688621899680551_n.jpg',
        basement: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/316452492_502687365226719_6715709208705704443_n.jpg',
        plasteringwork: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/362314376_952659222705987_4670557865023555617_n.jpg',
        plasteringwork1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/362320063_3483335545211701_7807918976285672218_n.jpg',
        garage: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/394023734_818665370054831_2319673976695579587_n.jpg',
        garage1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/400775416_251200684607803_4305312148947042445_n.jpg',
        staircase: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/301419186_485647296731706_4434717518933813726_n.jpg',
        outsidewall: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/301477510_788257452486300_3468918763267705551_n.jpg',
        outside1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/301764212_735842470832859_6447196507740470615_n.jpg',
        fence: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/301659134_632353244877355_6738065964194905488_n.jpg',
        livingroom: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455358243_1040804647710880_9029157963700971388_n.jpg',
        outsidework1: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/405199760_1087511898908825_8368930288452178769_n.jpg',
        hallway5: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/455713493_2245925719103601_1511375914324667979_n.jpg',
        fronthouse: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/309502524_116294801095502_2592632774461212836_n.jpg',
        doorswork: 'https://allstar-painting-images.s3.ap-southeast-2.amazonaws.com/images/318331979_157199517026164_426573793603708353_n.jpg',
       
    },
]