


import React from "react";
import "./App.css";
import Header from "./Navigation/Header";
import Footer from "./Navigation/Footer";
import Gallery from "./Pages/Gallery";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import NewService from "./Pages/NewService";
import './firebase'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/Contact" element = {<Contact />} />
          <Route path="/NewService" element={<NewService />} />
          {/* Define other routes here */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
