import React, { useEffect, useState } from 'react';
import { images } from '../images/content';

function GridGallery() {
  const [arrayState, setArrayState] = useState([]);

  useEffect(() => {
    const obj = images[0];
    const array = Object.entries(obj).map(([key, value]) => ({ id: key, image: value }));
    setArrayState(array);
  }, []);

  const flexContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px',
    padding: '20px',
  };

  const flexItemStyle = {
    flex: '1 1 calc(33.333% - 30px)', // 3 items per row with gap
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '380px',
    minWidth: '200px',
    // maxWidth: '30vw',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  

  return (
    <div className='gallery-div' style={flexContainerStyle}>
      {arrayState.map(item => (
        <div
          className='gallery-div-img'
          key={item.id}
          style={{
            ...flexItemStyle,
            backgroundImage: `url(${item.image})`,
          }}
        >
          {/* Item {item.id} */}
        </div>
      ))}
    </div>
  );
}

export default GridGallery;