import React from "react";
import "../App.css";
import { images } from "../images/content";

function App() {
  return (
    <div className="App">
      <div
        id="hero-section"
        style={{ backgroundImage: `url('${images[0].converpage}')` }}
        className="hero-section text-center bg-primary text-white py-5"
      >
        <h3>Bringing Colors to Your World</h3>
      </div>
      <div
        style={{
          background: "#4A5795", // Blue background
          color: "#ffffff", // White text
          textAlign: "center", // Center align text
          padding: "20px", // Padding around the content
          borderRadius: "5px", // Rounded corners
          margin: "20px 0", // Margin for top and bottom
          textTransform: "uppercase",
        }}
      >
        <h4>Get a free quote now!</h4>
        <h4>📞 0450854559</h4>
      </div>
      <div
        style={{
          textAlign: 'center', fontFamily: 'sans-serif',
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <h2>We offer Residential & Commercial Painting services</h2>
        <p>
          At the heart of our business is a commitment to 100% customer
          satisfaction. We understand the importance of your space, and we treat
          every project with the utmost care and professionalism. Our approach
          is simple: combine traditional techniques with the latest technology
          to produce stunning, durable finishes.
        </p>
      </div>
      <div className="services-section py-5">
        <div className="container">
          <h2 className="text-center mb-4">Our Services</h2>
          <div className="row justify-content-center">
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].wallpaper}
                  className="card-img-top"
                  alt="Service 1"
                />
                <div className="card-body">
                  <h5 className="card-title">Interior Painting</h5>
                  <p className="card-text">
                    High-quality interior painting services to bring your walls
                    to life.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].outsidework1}
                  className="card-img-top"
                  alt="Service 2"
                />
                <div className="card-body">
                  <h5 className="card-title">Exterior Painting</h5>
                  <p className="card-text">
                    Durable and weather-resistant painting solutions for
                    exteriors.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].staircase1}
                  className="card-img-top"
                  alt="Service 3"
                />
                <div className="card-body">
                  <h5 className="card-title">Decorative Painting</h5>
                  <p className="card-text">
                    Custom decorative painting to add a unique touch to your
                    spaces.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {/* New services */}
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].fronthouse}
                  className="card-img-top"
                  alt="Service 4"
                />
                <div className="card-body">
                  <h5 className="card-title">Wallpaper Installation</h5>
                  <p className="card-text">
                    Professional wallpaper installation for an exquisite finish.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].bedroom5}
                  className="card-img-top"
                  alt="Service 5"
                />
                <div className="card-body">
                  <h5 className="card-title">Color Consultation</h5>
                  <p className="card-text">
                    Expert color consultation to match your unique style and
                    preferences.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <img
                  src={images[0].hallway3}
                  className="card-img-top"
                  alt="Service 6"
                />
                <div className="card-body">
                  <h5 className="card-title">Stucco Repair</h5>
                  <p className="card-text">
                    Comprehensive stucco repair services to restore your home's
                    beauty.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery-section py-5">
        <div className="container">
          <h2 className="text-center mb-4">Gallery</h2>
          <div className="row no-gutters">
            {Object.values(images[0])
              .slice(6, 12)
              .map((image, index) => (
                <div key={index} className="col-md-4">
                  <img
                    src={image}
                    className="img-fluid"
                    alt={`Gallery ${index + 1}`}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="coverage-section py-5">
        <div className="container">
          <h2 className="text-center mb-4">Area of Coverage</h2>
          <div className="row">
            
            <div className="col-md-7">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d805795.6650766066!2d145.06117292951402!3d-37.91549246337676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC!5e0!3m2!1sen!2sau!4v1730267931058!5m2!1sen!2sau" style={{width:"40vw", height:"50vh", border:0,}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="col-md-4">
              <h3>We’ve got you covered —anywhere within 100km of Melbourne CBD!</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="about-section py-5 bg-light">
        <div className="container">
          <h2 className="text-center mb-4">About Us</h2>
          <p className="text-center">
            All Stars Painting, a proud sole trader business, has been setting
            the standard in the painting industry for over a decade. With a
            small, handpicked team of skilled professionals, we specialize in
            delivering high-quality painting services that not only meet but
            exceed your expectations.
          </p>
          <h4 className="text-center mt-4">Our Commitment</h4>
          <p className="text-center">
            At the heart of our business is a commitment to 100% customer
            satisfaction. We understand the importance of your space, and we
            treat every project with the utmost care and professionalism. Our
            approach is simple: combine traditional techniques with the latest
            technology to produce stunning, durable finishes.
          </p>
          <h4 className="text-center mt-4">Experience & Expertise</h4>
          <p className="text-center">
            With 10 years of experience under our belt, we have mastered the art
            of painting across a variety of settings. From residential homes to
            commercial spaces, our work speaks volumes of our dedication to
            quality and excellence. Our team is not just workers; they are
            craftsmen passionate about painting.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;